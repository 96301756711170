<template>
  <div>
    <b-container fluid="xl" id="app">
      <b-navbar toggleable="md" fixed="top">
        <div class="container">
          <b-navbar-brand href="https://fiberspark.com"><img src="./assets/fiberspark_logo.png" alt="Fiberspark"/>
          </b-navbar-brand>
          <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>
          <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="ml-auto">
              <b-nav-item href="https://www.fiberspark.com/about.php">
                About Us
              </b-nav-item>
              <b-nav-item href="https://www.fiberspark.com/faq.php">
                FAQ
              </b-nav-item>
              <b-nav-item href="https://www.fiberspark.com/testimonials.php">
                Testimonials
              </b-nav-item>
              <b-nav-item href="https://www.fiberspark.com/contact.php">
                Contact
              </b-nav-item>
              <b-nav-item href="https://portal.fiberspark.com/">
                Login
              </b-nav-item>
            </b-navbar-nav>
          </b-collapse>
        </div>
      </b-navbar>
      <div class="main mt-5">
        <router-view/>
      </div>
      <div class="small text-light">v{{ version }} {{ env }}</div>
    </b-container>
    <footer id="footer" class="text-center text-white w-100">
      <div class="py-4">
        <a href="https://www.fiberspark.com">Home</a> |
        <a href="https://www.fiberspark.com/about.php">About Us</a> |
        <a href="https://www.fiberspark.com/terms.php">Terms &amp; Policies</a> |
        <a href="https://www.fiberspark.com/contact.php">Contact</a> |
        <a href="#">Back to Top</a>
      </div>
      <div class="pb-4">
        &copy; Fiberspark Inc. {{ year }}
      </div>
    </footer>
  </div>
</template>

<script>
import {DateTime} from 'luxon'

export default {
  name: 'App',
  components: {},
  data() {
    return {}
  },
  computed: {
    year: function () {
      return DateTime.now().get('year')
    },
    version: function () {
      return process.env.VUE_APP_VERSION
    },
    env: function () {
      return process.env.NODE_ENV
    }
  },
  methods: {}
}
</script>


<style lang="scss">
#app {
  nav {
    padding: 14px;
    text-transform: uppercase;
    border-bottom: 1px solid #ccc;
    background: white;

    .navbar-brand img {
      width: 170px;
    }

    .nav-link {
      font-weight: bold;
      color: black !important;
      @media screen and (max-width: 767px) {
        text-align: center;
        border-bottom: 1px solid #dddddd;
      }

      &:hover, &:focus {
        color: #c3292f !important;
        @media screen and (max-width: 767px) {
          background: #c3292f;
          color: #fff !important;
        }
      }
    }

    @media screen and (max-width: 767px) {
      .nav_links .m a:hover {
        background: #c3292f;
        color: #fff !important;
      }
    }

    @media screen and (max-width: 767px) {
      //.nav_links .m a {
      //  display: block;
      //  border-bottom: 1px solid #ddd;
      //  margin: 0;
      //  padding: 15px 20px;
      //  text-align: center;
    }
  }
}
</style>
<script setup>
</script>
